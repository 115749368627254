import React from 'react';

const Line3: React.FC = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="186" height="47" viewBox="0 0 186 47" fill="none">
			<path
				d="M2.676 42.1882C20.3281 45.9716 40.1367 44.4394 58.1657 44.5433C86.4323 44.7062 114.931 46.4236 142.917
										41.9301C151.812 40.502 160.763 38.5988 169.109 35.4454C174.495 33.4103 181.818 30.3393 183.559 24.7666C187.049
										13.5999 168.919 8.77588 160.728 6.57069C142.176 1.57658 122.898 1.61177 103.685 2.3766C80.7526 3.28944 57.2401
										7.16911 35.2979 13.1844C25.5115 15.8673 9.47757 19.3302 3.39852 27.6702C0.143345 32.136 3.0473 32.5826 7.22788 32.3159"
				stroke="#FFDD60"
				strokeWidth="3"
				strokeLinecap="round"
			/>
		</svg>
	);
};

export default Line3;
