import React from 'react';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import FormikField from '@common/react/components/Forms/FormikField/FormikField';
import useRequest from '@common/react/hooks/useRequest';
import {
	phoneValidator,
	simpleNumberValidator,
} from '@common/react/utils/validationHelpers';
import { FormikPhoneControl } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';
import { Loading } from '@common/react/components/UI/Loading/Loading';

import { DatePickerReact } from '@app/components/UI/DatePicker/DatePicker';
import '@app/scss/components/contactForm.scss';
import Footprint from '@app/components/UI/Footprint/Footprint';
import {
	dateValidator,
	emailValidator,
	simpleStringFirstNameValidator,
	simpleStringMessageValidator,
	simpleStringValidator,
} from '@app/utils/validationHelpersForm';
import SubmitButton from '@app/components/UI/SubmitButton/SubmitButton';

enum SpeakingType {
	Live = 0,
	Virtual = 1
}

interface Values {
	name: string;
	lastName: string;
	email: string;
	phone: string;
	organization: string;
	date: number | null;
	location: string;
	text: string;
	speakingType: number;
}

const initialValues: Values = {
	lastName: '',
	name: '',
	location: '',
	organization: '',
	phone: '',
	date: null,
	text: '',
	email: '',
	speakingType: SpeakingType.Virtual,
};

const validationSchema = Yup.object().shape({
	name: simpleStringFirstNameValidator,
	lastName: simpleStringValidator,
	phone: phoneValidator,
	email: emailValidator,
	location: simpleStringValidator,
	organization: simpleStringValidator,
	date: dateValidator,
	text: simpleStringMessageValidator,
	speakingType: simpleNumberValidator,
});

const SpeakingForm: React.FC = () => {
	const [message, setMessage] = React.useState('');
	const [error, setError] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const request = useRequest();

	const handleSubmit = (values, formikHelpers) => {
		setError('');
		setMessage('');
		setLoading(true);

		request('speakingInquiry', values)
			.then((res) => {
				setTimeout(() => {
					setMessage('Successfully send.');
					formikHelpers.resetForm({ values: initialValues });
				}, 0);
				setTimeout(() => setMessage(''), 10000);
			})
			.catch((e) => {
				setError('Something went wrong');
				setTimeout(() => setLoading(false), 300);
				setTimeout(() => setError(''), 10000);
			})
			.finally(() => {
				setTimeout(() => setLoading(false), 300);
			});
	};

	return <div className="contact-form">
		<h2>
			To enquire about having Dr. Mary speak at your event fill out the form below:
		</h2>
		<Formik<Values>
			initialValues={initialValues}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
		>
			{
				(formikBag) => {
					return <Form>
						<div className="row">
							<div>
								<FormikField
									title=""
									containerClassName="form-group col-sm-6"
									fieldName="name"
									inputProps={{
										placeholder: 'First Name',
									}}
								/>
							</div>
							<FormikField
								title=""
								containerClassName="form-group col-sm-6"
								fieldName="lastName"
								inputProps={{
									placeholder: 'Last Name',
								}}
							/>
						</div>
						<div className="row">
							<div>
								<FormikField
									title=""
									containerClassName="form-group col-sm-6"
									fieldName="email"
									inputProps={{
										placeholder: 'Email',
									}}
								/>
							</div>
							<FormikField
								title=""
								fieldName="phone"
								containerClassName="col-sm-6 form-group"
								render={(fieldProps) => (
									<>
										<FormikPhoneControl
											id="phone"
											fieldProps={fieldProps}
											className="form-control"
											placeholder="Phone           ___ ____"
										/>
									</>
								)}
							/>
						</div>
						<div className="row">
							<FormikField
								title=""
								containerClassName="form-group col-sm-6"
								fieldName="organization"
								inputProps={{
									placeholder: 'Organization',
								}}
							/>
							<FormikField
								title=""
								containerClassName="form-group col-sm-6"
								fieldName="date"
								render={({ field, form }) => <DatePickerReact
									value={field.value}
									onChange={(value) => form.setFieldValue('date', value)}
								/>}
							/>
						</div>
						<div className="row">
							<div className="col-sm-6 radio-group">
								<label>
									<input
										name="speakingType"
										type="radio"
										value={SpeakingType.Live}
										checked={formikBag.values.speakingType === SpeakingType.Live}
										onChange={() => formikBag.setFieldValue('speakingType', SpeakingType.Live)}
									/>
									<span />
									Live
								</label>
								<label>
									<input
										name="speakingType"
										type="radio"
										value={SpeakingType.Virtual}
										checked={formikBag.values.speakingType === SpeakingType.Virtual}
										onChange={() => formikBag.setFieldValue('speakingType', SpeakingType.Virtual)}
									/>
									<span />
									Virtual
								</label>
							</div>
							<FormikField
								title=""
								containerClassName="form-group col-sm-6"
								fieldName="location"
								render={({ field, form }) => <div className="is-relative">
									<input
										{...field}
										placeholder="Location for live"
									/>
									<svg className="location-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
										<path
											d="M12 3C8.13997 3 5 6.19642 5 10.125C5 15.3814 11.4584 21.6289 11.7334 21.8925C11.8086 21.964 11.9043 22 12
											22C12.0957 22 12.1914 21.964 12.2666 21.8925C12.5416 21.6289 19 15.3814 19 10.125C19 6.19642 15.86 3 12 3ZM12 14.0833C9.85578
											14.0833 8.1111 12.3075 8.1111 10.125C8.1111 7.94249 9.85581 6.16665 12 6.16665C14.1442 6.16665 15.8889 7.94252 15.8889 10.125C15.8889
											12.3075 14.1442 14.0833 12 14.0833Z"
											fill="currentColor"
										/>
									</svg>
								</div>}
							/>
						</div>
						<div>
							<FormikField
								title=""
								containerClassName="form-group"
								fieldName="text"
								render={({ field, form }) => <div>
									<textarea
										{...field}
										placeholder="Message"
										className="form-control"
									/>
									<ImageLazy
										alt="cat"
										width={113}
										height={175}
										className="cat-pen"
										src="/images/CatPencil.png"
									/>
								</div>}
							/>
						</div>
						<div className="text-center">
							<SubmitButton
								loading={loading}
								error={error}
								message={message}
								formikBag={formikBag}
								textButton="Send message"
								textDone="Thank you for your speaking request. We will reach out to you shortly!"
							/>
						</div>
					</Form>;
				}
			}
		</Formik>
	</div>;
};

export default SpeakingForm;
