import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import '@app/scss/components/customSwiper.scss';

interface Props {
	events: boolean;
	upcoming?: Array<React.ReactNode>;
	prev?: Array<React.ReactNode>;
	videos?: Array<{
		title: string;
		link: string;
		img: string;
	}>;
}

const CustomSwiper: React.FC<Props> = ({
	events,
	upcoming,
	prev,
	videos,
}) => {
	const ref = React.useRef<HTMLImageElement | null>(null);
	const [visible, setVisible] = React.useState(false);
	const [swiper, setSwiper] = React.useState<any>();
	const [upcomingEvents, setUpcomingEvents] = React.useState(true);

	React.useEffect(() => {
		if (ref.current) {
			const intersectionObserver = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting) {
					setVisible(true);
				}
			}, {
				rootMargin: '50px',
				threshold: 0.01,
			});

			intersectionObserver.observe(ref.current);
			return () => intersectionObserver.disconnect();
		}
	}, [ref.current]);

	return (
		<div className="swiper-container" ref={ref}>
			<div className={visible ? 'swiper-bg' : ''}>
				{events && <div className="clearfix container">
					<h3
						className={upcomingEvents ? 'pull-left' : 'pull-left click'}
						onClick={() => setUpcomingEvents(true)}
					>
						Upcoming events
					</h3>
					<h3
						className={upcomingEvents ? 'pull-right click' : 'pull-right'}
						onClick={() => setUpcomingEvents(false)}
					>
						Previous events
					</h3>
				</div>}
				<div className="container is-relative">
					<button
						className="swiper-prev"
						onClick={() => swiper?.slidePrev()}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="49" height="21" viewBox="0 0 49 21" fill="none">
							<path
								d="M47.3427 9.34532C33.9784 8.91075 20.674 9.0944 7.36967 9.27805C11.5776 7.41745 15.9562 5.70015
								20.3049 3.67374C22.2532 2.75469 21.1601 -0.434695 19.0412 0.341052C13.1661 2.78909 7.60239 5.21462
								1.72737 7.66266C0.823519 8.03929 0.431572 8.84582 0.662181 9.60734C0.0995987 10.2706 -0.0917342 11.5295
								0.902079 12.0802C4.59604 14.6146 8.11937 17.0058 11.6727 19.706C13.5197 20.9732 15.4887 18.6519 13.923
								17.0531C12.046 15.4768 10.3397 14.0438 8.60339 12.3016C21.4258 11.9972 34.5895 11.9793 47.352 11.0567C48.7677
								11.11 48.4471 9.42111 47.3427 9.34532Z"
								fill="currentColor"
							/>
						</svg>
					</button>
					{upcomingEvents
						? <>
							<Swiper
								onBeforeInit={(swiper) => setSwiper(swiper)}
								breakpoints={{
									// when width <= n px
									575: {
										spaceBetween: 11,
										slidesPerView: 1,
									},
									700: {
										spaceBetween: 11,
										slidesPerView: 2,
									},
									1000: {
										spaceBetween: 15,
										slidesPerView: 3,
									},
									1400: {
										spaceBetween: 25,
										slidesPerView: 4,
									},
									1920: {
										spaceBetween: 30,
										slidesPerView: 4,
									},
								}}
							>
								{upcoming && upcoming.map((slide, i) => <SwiperSlide key={i}>
									{slide}
								</SwiperSlide>)}
								{videos && videos.map((slide, i) => <SwiperSlide key={i}>
									<a
										href={slide.link}
										className="slide"
										target="_blank"
										rel="nofollow noreferrer"
									>
										<img
											src={slide.img}
											alt="YouTube video preview"
											className="preview"
										/>
										<span>
											<div className="h3 is-relative">
												{slide.title}
											</div>
											<img
												src="/icons/youtubeSwiper.svg"
												className="youtube-svg"
											/>
										</span>
									</a>
								</SwiperSlide>)}
							</Swiper>
						</>
						: <>
							<Swiper
								onBeforeInit={(swiper) => setSwiper(swiper)}
								breakpoints={{
									// when width <= n px
									575: {
										spaceBetween: 11,
										slidesPerView: 1,
									},
									700: {
										spaceBetween: 11,
										slidesPerView: 2,
									},
									1000: {
										spaceBetween: 15,
										slidesPerView: 3,
									},
									1400: {
										spaceBetween: 25,
										slidesPerView: 4,
									},
									1920: {
										spaceBetween: 30,
										slidesPerView: 4,
									},
								}}
							>
								{prev && prev.map((slide, i) => <SwiperSlide key={i}>
									{slide}
								</SwiperSlide>)}
							</Swiper>
						</>
					}
					<button
						className="swiper-next"
						onClick={() => {
							swiper?.slideNext();
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="49" height="21" viewBox="0 0 49 21" fill="none">
							<path
								d="M1.30577 9.34532C14.6701 8.91075 27.9744 9.0944 41.2788 9.27805C37.0708 7.41745 32.6922 5.70015 28.3436
								3.67374C26.3952 2.75469 27.4883 -0.434695 29.6073 0.341052C35.4823 2.78909 41.046 5.21462 46.9211 7.66266C47.8249
								8.03929 48.2169 8.84582 47.9863 9.60734C48.5488 10.2706 48.7402 11.5295 47.7464 12.0802C44.0524 14.6146 40.5291
								17.0058 36.9758 19.706C35.1288 20.9732 33.1597 18.6519 34.7254 17.0531C36.6024 15.4768 38.3087 14.0438 40.045
								12.3016C27.2226 11.9972 14.0589 11.9793 1.29648 11.0567C-0.119274 11.11 0.201303 9.42111 1.30577 9.34532Z"
								fill="currentColor"
							/>
						</svg>
					</button>
				</div>
			</div>
		</div>
	);
};

export default CustomSwiper;
